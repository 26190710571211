import React from 'react'
import { AboutPage, CatalogPage, CircuitsPage, HomePage, OneDayTours, ReservationPage, ServicesPage, TemplatePage, TwoDaysPage } from '../pages';
import { Navigate, createBrowserRouter } from 'react-router-dom'
import { WrapTour } from '../tours/WrapTour';

const routes = [
  {
    path: '/:lang',
    element: <TemplatePage component={<HomePage/>} />
  },
  // {
  //   path: '/home/:lang',
  //   element: <TemplatePage component={<HomePage/>} />
  // },
  {
    path: '/:lang/our-services',
    element: <TemplatePage component={<ServicesPage />} />
  },
  {
    path: '/about-us',
    element: <TemplatePage component={<AboutPage />} />,
  },
  {
    path: '/one-day',
    element: <TemplatePage component={<OneDayTours />} />,
   },
   {
    path: '/two-and-three-days',
    element: <TemplatePage component={<TwoDaysPage />} />,
   },
   {
    path: '/:lang/circuits',
    element: <TemplatePage component={<CircuitsPage />} />,
   },
   {
    path: '/reservation',
    element: <TemplatePage component={<ReservationPage />} />
   },
   {
    path: '/catalog',
    element: <TemplatePage component={<CatalogPage />} />
   },
   // ENGLISH PAGES

  // {
  //   path: '/:lang',
  //   element: <TemplatePage component={<HomePageUS/>} />
  // },
   // REDIECT BACK HOME
   {
    path: '/*',
    element: <Navigate to="/es" replace={true} />
   },

  // TOUR WRAP
  {
    path: '/wrap',
    element: <TemplatePage component={<WrapTour/>} />
  },
];

export const AppRouter = createBrowserRouter(routes, {
  basename: '/',
});