import { useParams } from 'react-router-dom';
import { PreciosComponent } from '../components';
import '../styles/tours/WrapTour.css'

export const WrapTour = ({ tourInfo }) => {
  const { lang } = useParams();
  const { id, tour, tipoTour, descripcion, lugaresVisitar, salidas, incluye, noIncluye, preciosPorPersona, imagenes } = tourInfo;
  return (
    <div className="wrap-tour-container">
        <div className='wrap-tour-img'>
            {/* <img src={`${process.env.PUBLIC_URL}/images/${tourInfo.image}`} alt="" /> */}
            <img src={`../assets/toursImgs/${imagenes[0]}.jpeg`} alt={ `${tour}-travelCenterGuatemala`} />
        </div>
        <div className='wrap-tour-info'>
            <p className='preview-tour-title'><b className='preview-tour-main-title'>{ tour }</b> | { tipoTour }</p> 
        </div>
        <div className="accordion" id={ `accordion-${id}` }>
            <div className="card">
                <div className="card-header" id="headingOne">
                <h2 className="mb-0">
                    <button className="btn btn-link btn-block text-center collapsed border border-danger rounded-pill" type="button" data-toggle="collapse" data-target={`#collapseOne-${id}`} aria-expanded="true" aria-controls={`collapseOne-${id}`}>
                    { lang === 'en' ? 'Tour Description' : 'Descripción del Destino' }
                    </button>
                </h2>
                </div>

                <div id={`collapseOne-${id}`} className="collapse show" aria-labelledby="headingOne" data-parent={ `#accordion-${id}` }>
                <div className="card-body">
                    { descripcion }
                </div>
                </div>
            </div>
            <div className="card">
                <div className="card-header" id="headingTwo">
                <h2 className="mb-0">
                    <button className="btn btn-link btn-block text-center collapsed border border-warning rounded-pill" type="button" data-toggle="collapse" data-target={`#collapseTwo-${id}`} aria-expanded="false" aria-controls={`collapseTwo-${id}`}>
                    { lang === 'en' ? 'Tour Details' : 'Detalles del Tour' }
                    </button>
                </h2>
                </div>
                <div id={`collapseTwo-${id}`} className="collapse" aria-labelledby="headingTwo" data-parent={ `#accordion-${id}` }>
                <div className="card-body">
                    {/* SCHEDULES */}
                    <div className="wrap-tour-schedules">
                        <h3>{ lang === 'en' ? 'Departures' : 'Salidas' }</h3>
                        <span></span>
                        <div className="schedules">
                            {
                                salidas.map((salida, i) => {
                                    return (
                                        <span key={`salida${i}-${tour}-TravelCenterGuatemala`}>{salida}<br /></span>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <p></p>
                    {/* POINTS */}
                    <div className="wrap-tour-points">
                        {
                            (lugaresVisitar) &&
                            <>
                                <h3>{ lang === 'en' ? 'Places to Visit' : 'Lugares a Visitar' }:</h3>
                                <ul>
                                    {
                                        lugaresVisitar.map((lugar, i) => {
                                            return (
                                                <li key={`lugarAVisitar${i}-${tour}-TravelCenterGuatemala`}>{lugar}</li>
                                            )
                                        })
                                    }
                                </ul>
                                <p></p>
                            </>
                        }
                    </div>
                    {/* SERVICES */}
                    <p></p>
                    <div className="wrap-tour-services">
                        <h3>{ lang === 'en' ? 'Includes' : 'Incluye' }</h3>
                        <ul>
                            {
                                incluye.map((item, i) => {
                                    return (<li key={`inlcuye${i}-${tour}-TravelCenterGuatemala`}>{item}</li>)
                                })
                            }
                        </ul>
                        <p></p>
                        {
                            (noIncluye) &&
                            (
                                <>
                                    <h3>{ lang === 'en' ? 'Not included' : 'No Incluye' }</h3>
                                    <ul>
                                        {
                                            noIncluye.map((item, i) => {
                                                return (<li key={`no-incluye${i}-${tour}-TravelCenterGuatemala`}>{item}</li>)
                                            })
                                        }
                                    </ul>
                                </>
                            )
                        }
                    </div>
                </div>
                </div>
            </div>
            <div className="card">
                <div className="card-header" id="headingThree">
                <h2 className="mb-0">
                    <button className="btn btn-link btn-block text-center collapsed border border-success rounded-pill" type="button" data-toggle="collapse" data-target={`#collapseThree-${id}`} aria-expanded="false" aria-controls={`collapseThree-${id}`}>
                        { lang === 'en' ? 'Prices' : 'Precios' }
                    </button>
                </h2>
                </div>
                <div id={`collapseThree-${id}`} className="collapse" aria-labelledby="headingThree" data-parent={ `#accordion-${id}` }>
                <div className="card-body">
                    <PreciosComponent preciosPorPersona={preciosPorPersona} />
                </div>
                </div>
            </div>
        </div>
    </div>
  )
}
