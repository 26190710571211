import { Link, useParams } from 'react-router-dom';
import '../styles/components/Menu.css';
// import { NavbarComponent } from './NavbarComponent';

export const MenuComponent = () => {
  const { lang } = useParams();
  return (
    <>
        <div className="menu">
            <div className="menu-top-navbar">
              <div className="yellow-bar"></div>
              <img src="../assets/logo.png" alt="Travel Center Guatemala" />
              <div className="yellow-bar"></div>
            </div>
            {/* <NavbarComponent /> */}
            <div className="container-flags">
              <div className="flags">
                {
                  lang === 'es'
                  ? <Link to={`../en`}><img src="./assets/flag-usa.png" alt="English" /></Link>
                  : <Link to={`../es`}><img src="./assets/flag-esp.webp" alt="Español" /></Link>
                }
              </div>
            </div>
        </div>
    </>
  )
}
