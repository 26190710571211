import React from 'react';
import '../styles/main.css';
import tours from '../tours/tours.json';
import toursUS from '../tours/toursUS.json';
import { LandingPageComponent } from './LandingPageComponent';
import { WrapTour } from '../tours/WrapTour';
import { useParams } from 'react-router-dom';

export const HomePage = () => {
  const { lang } = useParams();

  // ToDo: load information from JSON file
  const { tours: toursList } = lang === 'en' ? toursUS : tours;
  return (
        <>
            <LandingPageComponent />
            {
              toursList.map((tour, i) => {
                return (<div className="tour-preview-item" key={`${i}-TravelCenterGuatemala`} id={tour.id}>
                  <WrapTour tourInfo={tour} />
                </div>)
              })
            }
        </>
  )
}
