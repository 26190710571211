import React from 'react';
import '../styles/tours/PreviewTour.css';
import { PreciosComponent } from '../components';

export const PreviewTourComponent = ({ tourInfo }) => {
  const { id, tour, tipoTour, descripcion, lugaresVisitar, salidas, incluye, noIncluye, preciosPorPersona, imagenes } = tourInfo;
  return (
    <>
        <div className="preview-tour" id={id}>
            <div className="preview-tour-container">
                <div className="preview-tour-img">
                    <img src={`../assets/toursImgs/${imagenes[0]}.jpeg`} alt={ `${tour}-travelCenterGuatemala`} />
                    {
                        (window.innerWidth >= 1170)
                        &&
                        <>
                            <img src={`../assets/toursImgs/${imagenes[1]}.jpeg`} alt={ `${tour}-travelCenterGuatemala`} />
                            <img src={`../assets/toursImgs/${imagenes[2]}.jpeg`} alt={ `${tour}-travelCenterGuatemala`} />
                        </>
                    }
                </div>
                <div className="preview-tour-content">
                    {
                        (window.innerWidth >= 1170)
                        ? <p className='preview-tour-title'><b className='preview-tour-main-title'>{ tour }</b> | { tipoTour }</p> 
                        // | <span className='preview-tour-price'><b>desde $ { preciosPorPersona[4].toFixed(2) }</b></span>
                        : <p className='preview-tour-title'><b className='preview-tour-main-title'>{ tour }</b><span className='preview-tour-subtitle'></span></p>
                        // <br /><span className='preview-tour-price'><b>desde $ { preciosPorPersona[4].toFixed(2) }</b></span>
                    }
                    <p>{ descripcion }</p>
                    {
                        (lugaresVisitar) &&
                        <>
                            <h3>Lugares a Visitar:</h3>
                            <ul>
                                {
                                    lugaresVisitar.map((lugar, i) => {
                                        return (
                                            <li key={`lugarAVisitar${i}-${tour}-TravelCenterGuatemala`}>{lugar}</li>
                                        )
                                    })
                                }
                            </ul>
                            <p></p>
                        </>
                    }
                    <h3>Salidas</h3>
                    <span></span>
                    <div className="schedules">
                        {
                            salidas.map((salida, i) => {
                                return (
                                    <span key={`salida${i}-${tour}-TravelCenterGuatemala`}>{salida}<br /></span>
                                )
                            })
                        }
                    </div>
                    <p></p>
                    <h3>Incluye</h3>
                    <ul>
                        {
                            incluye.map((item, i) => {
                                return (<li key={`inlcuye${i}-${tour}-TravelCenterGuatemala`}>{item}</li>)
                            })
                        }
                    </ul>
                    <p></p>
                    {
                        (noIncluye) &&
                        (
                            <>
                                <h3>No Inlcuye</h3>
                                <ul>
                                    {
                                        noIncluye.map((item, i) => {
                                            return (<li key={`no-incluye${i}-${tour}-TravelCenterGuatemala`}>{item}</li>)
                                        })
                                    }
                                </ul>
                            </>
                        )
                    }
                    {/* <h3 style={{color: 'red'}}>Precio por persona</h3>
                    <table>
                        <thead>
                            <tr>
                                <th>1</th>
                                <th>2</th>
                                <th>3</th>
                                <th>4</th>
                                <th>5</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>${preciosPorPersona[0]}</td>
                                <td>${preciosPorPersona[1]}</td>
                                <td>${preciosPorPersona[2]}</td>
                                <td>${preciosPorPersona[3]}</td>
                                <td style={{color: 'red'}}>${preciosPorPersona[4]}*</td>
                            </tr>
                        </tbody>
                        
                    </table> */}
                    {/* <p className='importantNotes' >* Tarifa aplica si compran juntos</p>
                    <p className='importantNotes' > Aceptamos pagos con tarjeta de crédito, más 10% de recargo</p> */}
                    <PreciosComponent preciosPorPersona={ preciosPorPersona } />
                </div>
            </div>
        </div>
    </>
  )
}
