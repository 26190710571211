import React from 'react'
import '../styles/pages/templatepage.css'

export const AboutPage = () => {
    return (
      <>
        <div className='container-page'>
            <h4>About</h4><br/>
            <p>Sunt ipsum veniam excepteur est esse elit sint. Ut labore enim anim minim ipsum aute fugiat nisi veniam ullamco cillum officia et. Pariatur ea consequat in est eu. Dolore duis sint anim eiusmod et voluptate aliquip Lorem occaecat esse incididunt consequat excepteur ut. Ullamco deserunt eiusmod sit deserunt eu qui nostrud.</p>
            <p>Do laboris consequat irure culpa labore consequat dolore labore cupidatat magna aliqua. Labore officia consectetur occaecat ea. Mollit minim voluptate et duis veniam.</p>
            <p>Veniam cupidatat laborum minim qui sunt minim nisi sint ea culpa est sunt aute anim. Ea incididunt veniam sit cillum ullamco labore nisi labore duis sint nulla. Magna nisi ullamco cupidatat exercitation ut occaecat occaecat sit. Fugiat ex commodo Lorem labore aute veniam non duis aliqua do.</p>
            <p>Ex cillum pariatur magna et. Non dolor Lorem adipisicing aliqua deserunt. Commodo sint aute aute esse deserunt irure duis fugiat deserunt proident.</p>
        </div>
    </>
  )
}
