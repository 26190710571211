import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { AppRouter } from './routers/AppRouter';
import './App.css';

export const TravelCenterGuatemala = () => {
  return (
    <>
        <RouterProvider router={AppRouter} />
    </>
  )
}
