import { v4 as uuidv4 } from "uuid";
import { CardComponent } from '../../components/CardComponent';

export const cards = [
    {
      key: uuidv4(),
      content: (
        <CardComponent imagen="../assets/ANTIGUA GUATEMALA.jpg" url={`..#antigua-home`} />
      ),
    },
    {
      key: uuidv4(),
      content: (
        <CardComponent imagen="../assets/LAGO DE ATITLAN.jpg" url={`..#lago-home`} />
      ),
    },
    {
      key: uuidv4(),
      content: (
        <CardComponent imagen="../assets/CHICHICASTENANGO.jpg" url={`..#chichi-home`} />
      ),
    },
    {
      key: uuidv4(),
      content: (
        <CardComponent imagen="../assets/TIKAL.jpg" url={`..#tikal-home`} />
      ),
    },
    {
      key: uuidv4(),
      content: (
        <CardComponent imagen="../assets/SEMUC CHAMPEY.jpg" url={`..#semuc-home`} />
      ),
    },
  ];