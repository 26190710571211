import { ContactComponent, FooterComponent, MenuComponent, TopArrowComponent } from '../components'

export const TemplatePage = ({component}) => {
  return (
    <>
        <MenuComponent />
        { component }
        <ContactComponent />
        <TopArrowComponent />
        <FooterComponent />
    </>
  )
}
