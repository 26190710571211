import { useParams } from 'react-router-dom'
import '../styles/components/Footer.css'

export const FooterComponent = () => {
  const { lang } = useParams();
  return (
    <footer>
        <div className="footer-row">
            <div>
                <span>One Day Tours</span>
            </div>
            <div>
                <span>{ lang === 'en' ? 'Circuits' : 'Circuitos' }</span>
            </div>
            <div>
                <span>{ lang === 'en' ? 'Servicios' : 'Servicios' }</span>
            </div>
        </div>
        <div className="footer-row-media">
            <a href="https://www.facebook.com/turismoguate502" className="socialLink" target="_blank" rel="noreferrer"><span><i className="fa-brands fa-facebook"></i></span></a>
            <a href="https://www.instagram.com/toursxguate" className="socialLink" target="_blank" rel="noreferrer"><span><i className="fa-brands fa-instagram"></i></span></a>
            <a href="https://www.twitter.com/turismo_guate_" className="socialLink" target="_blank" rel="noreferrer"><span><i className="fa-brands fa-twitter"></i></span></a>
            <a href="https://www.youtube.com/@TurismoGuate" className="socialLink" target="_blank" rel="noreferrer"><span><i className="fa-brands fa-youtube"></i></span></a>
            <a href="https://www.tiktok.com/@TurismoGuate" className="socialLink" target="_blank" rel="noreferrer"><span><i className="fa-brands fa-tiktok"></i></span></a>
        </div>
        <hr />
        <div className="footer-final">
            <span>{ String.fromCharCode(174 ) } Travel Center Guatemala - { new Date().getFullYear() }</span>
            <span className='privacyPolicy'>{ lang === 'en' ? 'Privacy Policy' : 'Política de Privacidad' }</span>
            <span className='termsConditions'>{ lang === 'en' ? 'Terms and Conditions' : 'Términos y Condiciones' }</span>
        </div>
    </footer>
  )
}
