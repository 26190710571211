import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { LandingPageComponent } from './LandingPageComponent';
import Swal from 'sweetalert2';
import '../styles/pages/catalog.css'

export const CatalogPage = () => {
  const { pathname } = useLocation();
  const isCatalog = pathname === '/catalog';

  const getLanguage = () => {
    Swal.fire({
        title: '',
        icon: 'question',
        confirmButtonText: '<span>Español 🇪🇸</span>',
        confirmButtonColor: '#336699',
        showCancelButton: true,
        cancelButtonText: '<span>English 🇺🇸</span>',
        cancelButtonColor: '#87CEEB',
    }).then( result => {
        if(result.isConfirmed) {
            window.location = "https://drive.google.com/file/d/1XqLZZ1V3JMKiDex7RvNTCNPQtS9P25Mh/view?usp=drive_link";
        } else if (result.dismiss === 'cancel') {
            window.location = "https://drive.google.com/file/d/1ezK4w3MEiKMTQT8gJj2rOtrPR9bpCel4/view?usp=sharing";
        }
    });
  };

  useEffect(() => {
    getLanguage();
  }, [isCatalog]);
  

  return (
    <div className="catalog-container" onLoadStart={ getLanguage }>
        <LandingPageComponent />
    </div>
  )
}
