import React, { useState } from "react";
import { useSpring, animated } from "react-spring";
import PropTypes from 'prop-types';
import Styles from "../styles/components/Card.module.css";

export const CardComponent = ({ imagen, url }) => {
  const [show, setShown] = useState(false);

  const props3 = useSpring({
    transform: show ? "scale(1.03)" : "scale(1)",
    boxShadow: show
      ? "0 20px 25px rgb(0 0 0 / 25%)"
      : "0 2px 10px rgb(0 0 0 / 8%)"
  });

  const handleClick = e => {
    e.preventDefault();
    const elementId = url.split("#")[1];
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        bock: 'center',
        inline: 'nearest',
      });
    } else {
      const lazyElement = document.getElementById(elementId);
      setTimeout(() => {
        alert(lazyElement)
        lazyElement.scrollIntoView({
          behavior: 'smooth',
          bock: 'center',
          inline: 'nearest',
        });
      }, 400);
    }
  };
  return (
    <animated.div
      className={Styles.card}
      style={props3}
      onMouseEnter={() => setShown(true)}
      onMouseLeave={() => setShown(false)}
    >
      {/* toDo: Create a link to take to each tour */}
      <a href={`${url}`} onClick={ handleClick }>
        <img src={imagen} alt={`${imagen}-TravelCenterGuatemala`} />
      </a>
    </animated.div>
  );
}

CardComponent.propTypes = {
  url: PropTypes.string.isRequired,
};

