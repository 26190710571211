import React from 'react'
import '../styles/pages/landingPage.css'
import { cards } from '../ui/carousel/cards'
import { CarouselComponent } from '../components/CarouselComponent'

export const LandingPageComponent = () => {
  const widthScreen = window.innerWidth;
  let carouselWidth = '80%';
  let margin = '10vh';
  let height = '77vh';
  
  if (widthScreen >= 1170){
    carouselWidth = "80%";
    margin = '17vh auto'
    height = '60vh';
  } else {
    carouselWidth = '0%';
    margin = '0vh 0vw 0vh 19vw'
    height = '77vh';
  }
  
  return (
    <>
      <CarouselComponent
                cards={cards}
                height={height}
                width={carouselWidth}
                margin={margin}
                offset={2}
                showArrows={false}
            />
    </>
  )
}
