

export const ReservationPage = () => {
  return (
    <div className='container-page' style={{ paddingTop: '17%', }}>
        <form>
            <label htmlFor="Nombre">Nombre</label>
            <input type="text" name="Nombre" className="form-control" />
            <br />
            <label htmlFor="Telefono">Telefono</label>
            <input type="phone" name="Telefono" className="form-control" />
            <label htmlFor="Fecha">Fecha</label>
            <input type="date" name="Fecha" className="form-control" />
            <label htmlFor="Tour">Tour</label>
            <div className="form-group form-group-lg">
                <button className="btn btn-outline-primary form-control dropdown-toggle" data-toggle="dropdown" aria-expanded="false">Tour</button>
                <div className="dropdown-menu">
                    <a href="/" className="dropdown-item">Antigua Guatemala</a>
                    <a href="/" className="dropdown-item">Lago de Atitlán</a>
                    <a href="/" className="dropdown-item">Chichicastenango</a>
                    <a href="/" className="dropdown-item">Tikal</a>
                    <a href="/" className="dropdown-item">Volcán Pacaya</a>
                    <a href="/" className="dropdown-item">Volcán Acatenango</a>
                    <a href="/" className="dropdown-item">Semuc Champey</a>
                    <a href="/" className="dropdown-item">City Tour</a>
                    <a href="/" className="dropdown-item">Místico</a>
                    <a href="/" className="dropdown-item">Hobbitenango & Antigua Guatemala</a>
                </div>
            </div>
            <div className="form-group">
                <label htmlFor="Origen">Origen</label>
                <button className="btn btn-outline-success form-control dropdown-toggle" data-toggle="dropdown" aria-expanded="false">Origen</button>
                <div className="dropdown-menu">
                    <a href="/" className="dropdown-item">Guatemala City</a>
                    <a href="/" className="dropdown-item">Antigua Guatemala</a>
                    <a href="/" className="dropdown-item">Flores</a>
                </div>
            </div>
            <div className="form-group">
                <label htmlFor="Adultos">Adultos</label>
                <input type="number" className="form-control" />
                <label htmlFor="Ninos">Niños</label>
                <input type="number" name="Ninos" className="form-control" />
            </div>
            <button className="btn btn-primary">Reservar</button>
            <br /><br />
        </form>
    </div>
  )
}
