import '../styles/components/TopArrow.css';

export const TopArrowComponent = () => {

  const handleClick = e => {
    e.preventDefault();
    window.scrollTo(0, 0);
  };
  return (
    <>
        <div className="top-arrow" onClick={ handleClick }>
            <img src="./assets/angulo-hacia-abajo.png" alt="arrow" />
        </div>
    </>
  )
}
