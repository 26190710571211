import { useParams } from 'react-router-dom'
import '../styles/components/Precios.css'

export const PreciosComponent = ({ preciosPorPersona }) => {
  const { lang } = useParams();
  return (
    <div className='prices-component'>
        <div className="prices-column prices-shared-box">
            <h3>{ lang === 'en' ? 'Collective Tour' : 'Tour Compartido' }</h3>
            <p><b>USD { preciosPorPersona[3].toFixed(2) }</b></p>
        </div>
        <div className="prices-shared-conditions">
            <ul>
                <li>{ lang === 'en' ? 'Price per person' : 'Precio por persona' }</li>
                <li>{ lang === 'en' ? 'Minimum 2 people' : 'Mínimo 2 personas' }</li>
                <li>{ lang === 'en' ? 'Fee if traveling alone' : 'Suplemento al viajar solo' } ${ preciosPorPersona[2].toFixed(2) }</li>
                <li>{ lang === 'en' ? 'Depending on availability' : 'Sujeto a disponibilidad' }</li>
            </ul>
        </div>
        <div className="prices-private-box">
            <h3>{ lang === 'en' ? 'Private Tour' : 'Tour Privado' }</h3>
            <p><b>USD { preciosPorPersona[1].toFixed(2) }</b></p>
        </div>
        <div className="prices-private-conditions">
            <ul>
                <li>{ lang === 'en' ? 'Price per person' : 'Precio por persona' }</li>
                <li>{ lang === 'en' ? 'Minimum 2 people' : 'Mínimo 2 personas' }</li>
                <li>{ lang === 'en' ? 'Fee if traveling alone' : 'Suplemento al viajar solo' } ${ preciosPorPersona[0].toFixed(2) }</li>
            </ul>
        </div>
    </div>
  )
}
