import React from 'react'
import tours from '../tours/toursDays.json'
import { PreviewTourComponent } from '../tours/PreviewTourComponent';

export const TwoDaysPage = () => {
  const { tours: toursList } = tours;
  return (
    <>
        <div className="container-page">
            <h4>Tours de 2 y 3 Dias</h4>
            {
              toursList.map((tour, i) => {
                return (<div className="tour-preview-item" key={`${i}-TravelCenterGuatemala`}>
                  <PreviewTourComponent tourInfo={tour} />
                </div>)
              })
            }
        </div>
    </>
  )
}
