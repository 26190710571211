import React from 'react'
import { LandingPageComponent } from './LandingPageComponent'
import { FooterComponent } from '../components/FooterComponent'

export const OneDayTours = () => {
  return (
    <>
        <LandingPageComponent />
        <div className="container-page">
            <h4>Tours de 1 Dia</h4>
        </div>
        <FooterComponent />
    </>
  )
}
