import React from 'react'
import '../styles/pages/templatepage.css'

export const ServicesPage = () => {
  return (
    <>
      <div className='container-page'>ServicesPage</div>
      <ul>
        <li>Item 1</li>
        <li>Item 2</li>
        <li>Item 3</li>
        <li>Item 4</li>
        <li>Item 5</li>
        <li>Item 6</li>
        <li>Item 7</li>
        <li>Item 8</li>
        <li>Item 9</li>
        <li>Item 10</li>
        <li>Item 11</li>
        <li>Item 12</li>
        <li>Item 13</li>
        <li>Item 14</li>
        <li>Item 15</li>
        <li>Item 16</li>
        <li>Item 17</li>
        <li>Item 18</li>
        <li>Item 19</li>
        <li>Item 20</li>
        <li>Item 31</li>
        <li>Item 32</li>
        <li>Item 33</li>
        <li>Item 34</li>
        <li>Item 35</li>
        <li>Item 36</li>
        <li>Item 37</li>
        <li>Item 38</li>
        <li>Item 39</li>
        <li>Item 40</li>
      </ul>
    </>
  )
}
