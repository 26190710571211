import Carousel from "react-spring-3d-carousel";
import { useState, useEffect } from "react";
import { config } from "react-spring";
import '../styles/components/carousel.css';

export const CarouselComponent = (props) => {
  const widthScreen = window.innerWidth;
  let padding_top = '11.5vh', margin_bottom = '5vh';
  if(widthScreen > 1280){
    padding_top = '0vh';
    margin_bottom = '0vh';
  }else{
    padding_top = '19vh';
    margin_bottom = '5vh';
  }
  const table = props.cards.map((element, index) => {
    return { ...element, onClick: () => setGoToSlide(index) };
  });

  const [offsetRadius, setOffsetRadius] = useState(2);
  const [showArrows, setShowArrows] = useState(false);
  const [goToSlide, setGoToSlide] = useState(0);
  const [cards] = useState(table);

  useEffect(() => {
    setOffsetRadius(props.offset);
    setShowArrows(props.showArrows);
  }, [props.offset, props.showArrows]);

  setTimeout(() => {
    if (goToSlide === 5) setGoToSlide(0);
    
    setGoToSlide( goToSlide + 1 );
  }, 7500);

  return (
    <div
      style={{ width: props.width, height: props.height, margin: props.margin, paddingTop: padding_top, marginBottom: margin_bottom, }}
    >
      <Carousel
        slides={cards}
        goToSlide={goToSlide}
        offsetRadius={offsetRadius}
        showNavigation={showArrows}
        animationConfig={config.gentle}
        className="carousel"
      />
    </div>
  );
}
