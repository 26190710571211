import { useParams } from "react-router-dom"


export const CircuitsPage = () => {
  const { lang } = useParams();
  return (
    <>
        <div className="container-page">
            <h4>{ lang === 'en' ? 'Circuits' : 'Circuitos'}</h4>
            <h5>Tikal | Crater Azúl | Grutas Actun Kan</h5>
            <img src="../assets/circuits1.jpeg" alt="Circuito 1" style={{ width: '100%', height: 'auto', }} />
            <h5>Antigua Guatemala | Lago de Atitlán | Chichicastenango</h5>
            <img src="../assets/circuito2.jpeg" alt="Circuito 1" style={{ width: '100%', height: 'auto', }} />
        </div>
    </>
  )
}
